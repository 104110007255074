exports.components = {
  "component---src-layouts-single-class-page-index-js": () => import("./../../../src/layouts/SingleClassPage/index.js" /* webpackChunkName: "component---src-layouts-single-class-page-index-js" */),
  "component---src-layouts-single-product-page-index-js": () => import("./../../../src/layouts/SingleProductPage/index.js" /* webpackChunkName: "component---src-layouts-single-product-page-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-events-celebrations-learn-more-tsx": () => import("./../../../src/pages/events/celebrations/learn-more.tsx" /* webpackChunkName: "component---src-pages-events-celebrations-learn-more-tsx" */),
  "component---src-pages-events-corporate-events-learn-more-tsx": () => import("./../../../src/pages/events/corporate-events/learn-more.tsx" /* webpackChunkName: "component---src-pages-events-corporate-events-learn-more-tsx" */),
  "component---src-pages-events-index-tsx": () => import("./../../../src/pages/events/index.tsx" /* webpackChunkName: "component---src-pages-events-index-tsx" */),
  "component---src-pages-events-school-field-trips-learn-more-tsx": () => import("./../../../src/pages/events/school-field-trips/learn-more.tsx" /* webpackChunkName: "component---src-pages-events-school-field-trips-learn-more-tsx" */),
  "component---src-pages-fa-qs-js": () => import("./../../../src/pages/FAQs.js" /* webpackChunkName: "component---src-pages-fa-qs-js" */),
  "component---src-pages-gift-cards-js": () => import("./../../../src/pages/gift-cards.js" /* webpackChunkName: "component---src-pages-gift-cards-js" */),
  "component---src-pages-glass-classes-birthday-parties-js": () => import("./../../../src/pages/glass-classes/birthday-parties.js" /* webpackChunkName: "component---src-pages-glass-classes-birthday-parties-js" */),
  "component---src-pages-glass-classes-class-picker-index-js": () => import("./../../../src/pages/glass-classes/class-picker/index.js" /* webpackChunkName: "component---src-pages-glass-classes-class-picker-index-js" */),
  "component---src-pages-glass-classes-corporate-events-js": () => import("./../../../src/pages/glass-classes/corporate-events.js" /* webpackChunkName: "component---src-pages-glass-classes-corporate-events-js" */),
  "component---src-pages-glass-classes-index-js": () => import("./../../../src/pages/glass-classes/index.js" /* webpackChunkName: "component---src-pages-glass-classes-index-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kits-bundles-js": () => import("./../../../src/pages/kits/bundles.js" /* webpackChunkName: "component---src-pages-kits-bundles-js" */),
  "component---src-pages-kits-community-frames-js": () => import("./../../../src/pages/kits/community-frames.js" /* webpackChunkName: "component---src-pages-kits-community-frames-js" */),
  "component---src-pages-kits-event-kits-js": () => import("./../../../src/pages/kits/event-kits.js" /* webpackChunkName: "component---src-pages-kits-event-kits-js" */),
  "component---src-pages-kits-glass-kits-js": () => import("./../../../src/pages/kits/glass-kits.js" /* webpackChunkName: "component---src-pages-kits-glass-kits-js" */),
  "component---src-pages-kits-index-js": () => import("./../../../src/pages/kits/index.js" /* webpackChunkName: "component---src-pages-kits-index-js" */),
  "component---src-pages-old-glass-classes-js": () => import("./../../../src/pages/old-glass-classes.js" /* webpackChunkName: "component---src-pages-old-glass-classes-js" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

