import { useState } from 'react'
interface HookState {
  loading: boolean
  success: boolean
}
export const useEmailSubscribe = (): [
  (email: string) => void,
  HookState,
  () => void
] => {
  const [apiState, setApiState] = useState<HookState>({
    loading: false,
    success: false,
  })
  const subscribe = async (email: string) => {
    setApiState(prev => ({ ...prev, loading: true }))
    const fields = new FormData()

    fields.append('EMAIL', email)

    fetch(
      'https://brazeestreetstudios.us11.list-manage.com/subscribe/post?u=ab665e2fc330e1e640ec451bb&amp;id=4567f9ab98&amp;f_id=00fdc2e1f0',
      {
        method: 'POST',
        mode: 'no-cors',
        body: fields,
      }
    )
      .then(() => {
        setApiState(prev => ({ ...prev, success: true, loading: false }))
      })
      .catch(err => console.log(err))
    //   .finally(() => setApiState(prev => ({ ...prev, loading: false })))
  }

  const reset = () => {
    setApiState({ loading: false, success: false })
  }

  return [subscribe, { ...apiState }, reset]
}
